<template>
    <NuxtLayout name="default">
        <GameMenu name="Pet Simulator 99" image="/images/games/icons/ps99.png" :links="links" />
        <NuxtLayout name="ads">
            <slot />
        </NuxtLayout>
    </NuxtLayout>
</template>

<script setup lang="ts">
const links = [
    { title: 'Items', to: '/ps-99/items' },
    { title: 'Trade Calculator', to: '/ps-99/calculate' },
    { title: 'Trade Ads', to: '/ps-99/ads' },
    { title: 'Value Changes', to: '/ps-99/value-changes' }
]

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Pet Simulator 99 | RoValue` : 'Pet Simulator 99 - RoValue'
    }
})
</script>
